import styles from './smallButton.module.css'

const SmallButton = ({bgColor, border, label, color, disabled, onClick}) => {
  
  return (
    <button onClick={onClick} disabled={disabled} style={{backgroundColor: bgColor, border: border, color: color}} className={disabled ? `${styles.smallBtn} ${styles.disabled}` : styles.smallBtn}>
      {label}
    </button>
  )
}

export default SmallButton
