import styles from './pagination.module.css'
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";

const Pagination = () => {
  return (
    <div className={styles.pageination}>
      <span>Showing 1 to 11 of 60 records</span>
      <div>
        <MdKeyboardArrowLeft size={30} />
        <MdKeyboardArrowRight size={30} />
      </div>
    </div>
  )
}

export default Pagination
