import styles from "./medicalSessionPatientInfo.module.css";
import Card from "../card/card";
import patientPfp from "../../assets/images/medicalSessionImg.png";

const MedicalSessionPatientInfo = () => {
  return (
    <Card bgColor={"#4860A1"}>
      <div className={styles.wrapper}>
        <div>
          <img src={patientPfp} alt="" />
        </div>
        <div className={styles.details_wrapper}>
          <div className={styles.first_sub_details}>
            <div className={styles.detail}>
              <h1>Johnson David</h1>
              <span>Health Checkup</span>
            </div>
            <div className={styles.details}>
              <h3>Patient ID</h3>
              <span>122-35-72-567</span>
            </div>
          </div>
          <div className={styles.sub_details}>
            <div className={styles.details}>
              <h3>D.O.B</h3>
              <span>8th October, 1998</span>
            </div>
            <div className={styles.details}>
              <h3>Sex</h3>
              <span>Female</span>
            </div>
            <div className={styles.details}>
              <h3>Age</h3>
              <span>25yrs</span>
            </div>
            <div className={styles.details}>
              <h3>Weight</h3>
              <span>67kg</span>
            </div>
          </div>
          <div className={styles.sub_details}>
            <div className={styles.details}>
              <h3>Height</h3>
              <span>182cm</span>
            </div>
            <div className={styles.details}>
              <h3>Genotype</h3>
              <span>AA</span>
            </div>
            <div className={styles.details}>
              <h3>Blood group</h3>
              <span>O+</span>
            </div>
            <div className={styles.details}>
              <h3>Last Appointment</h3>
              <span>4th September,2023</span>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default MedicalSessionPatientInfo;
