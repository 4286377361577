import styles from "./labTestItem.module.css";
import Card from "../../../components/card/card";
import SmallButton from "../../../components/small-button/smallButton";
import { useState } from "react";
import Modal from "../../../components/modal/Modal";
import { LuClock4 } from "react-icons/lu";


const LabTestItem = ({docName, specialty, patientName, patientId, message}) => {
  const [isDisabled, setIsDisabled] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  

  const popUpModal = () => {
    setOpenModal(true)
  }
  const CloseModal = () => {
    setOpenModal(false)
  }



  return (
    <Card>
      <div className={styles.labListItem}>
        <div className={styles.docpfp}>

        </div>
        <div className={styles.docInfo}>
          <div className={styles.testTitle}>
            <div>
              <h3 className={styles.docName}>{docName}</h3>
              <p className={styles.docSpecialty}>{specialty}</p>
            </div>
            <p>Patient ID: {patientId}</p>
          </div>
          <p>
            {message} <b>{patientName}</b> with the ID: 
             <b>{patientId}</b>
          </p>
          <div className={styles.groupBtn}>
            <div>
              <SmallButton onClick={popUpModal} label={'Accept'} bgColor={'#23408E'} />
              <SmallButton label={'Decline'} bgColor={'#EEEEEE'} border={'1px solid #919FC6'} color={'#4860A1'} />
            </div>
            <div>
              <SmallButton disabled={isDisabled} label={'Send result'} bgColor={'#23408E'} />
              <SmallButton disabled={isDisabled} label={'Check additional note'} bgColor={'#EEEEEE'} border={'1px solid #919FC6'} color={'#4860A1'} />
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={openModal} onClose={CloseModal} >
        <div className={styles.timerContainer}>
          <h3 className={styles.docName}>Estimated Time</h3>
          <div>
            <p>Enter time</p>
            <div className={styles.timerInputGroup}>
              <div className={styles.hourInput}>
                <input type="text" />
                <p>Hour</p>
              </div>
              <p className={styles.columnText}>:</p>
              <div className={styles.minuteInput}>
                <input type="text" />
                <p>Minute</p>
              </div>
            </div>
            <div className={styles.timerActions}>
              <LuClock4 /> 
              <p>Cancel</p>
              <p>OK</p>
            </div>
          </div>
        </div>
      </Modal>

    </Card>
  );
};

export default LabTestItem;
