import styles from './navbar.module.css'
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoIosSearch } from "react-icons/io";
import { MdRefresh } from 'react-icons/md';


const Navbar = ({title}) => {
  return (
    <nav className={styles.navWrapper}>
      <h3>{title}</h3>

      <div className={styles.searchInput}>
        <IoIosSearch className={styles.searchIcon} />
        <input type="text" placeholder='Search  Patient, ID, Doctor' />
      </div>
      {/* <MdRefresh className={styles.refreshIcon} /> */}
      <IoIosNotificationsOutline className={styles.notification} />
    </nav>
  )
}

export default Navbar
