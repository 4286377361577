import Wrapper from "../../components/wrapper/wrapper";
import LabTestItem from "../lab-test-request/lab-test-item/labTestItem";
import { PrescriptionRequestData } from "./prescriptionRequestData";

const PrescriptionRequest = () => {
  return (
    <Wrapper navbarTitle={"Lab Tests Request"}>
      {PrescriptionRequestData.map((item) => (
        <LabTestItem
          key={item.id}
          docName={item.docName}
          specialty={item.specialty}
          patientName={item.patientName}
          patientId={item.patientId}
          message={item.message}
        />
      ))}
    </Wrapper>
  );
};

export default PrescriptionRequest;
