import styles from './patientList.module.css'
import { IoPrintOutline } from "react-icons/io5";
import { IoMdOpen } from "react-icons/io";
import DonationsButton from '../../components/donationsButton/donationsButton'
import PatientTable from '../../components/patientTable/patientTable';
import Wrapper from '../../components/wrapper/wrapper';

const PatientList = () => {
  return (
    <Wrapper navbarTitle={"Patient List"}>
    <div className={styles.group}>
      <div className={styles.filterGroup}>
        <input type="text" placeholder='Search by Name or by ID' />
        <select name="" id="">
          <option value="">Search by year</option>
        </select>
      </div>
      <div className={styles.secondfilterGroup}>
      <DonationsButton label={'Add Patient'} />
        <IoMdOpen className={styles.refreshIcon} />
        <IoPrintOutline className={styles.refreshIcon} />
      </div>
      </div>
    
      <PatientTable height={'52px'} />  
    </Wrapper>
  )
}

export default PatientList
