import { useState } from "react";
import { FaBars } from "react-icons/fa";
import styles from "./sidebar.module.css";
import { SidebarData } from "./sidebarData";
import profilepicture from "../../assets/images/Ellipse 4.png";
import { IoCloseOutline } from "react-icons/io5";
import { IconContext } from "react-icons";

const Sidebar = ({ adminName }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#4860A1", size: "50px" }}>
        <div>
          <FaBars className={styles.menuIcon} onClick={toggleSidebar} />
        </div>
      </IconContext.Provider>
      <aside className={`${styles.wrapper} ${isOpen ? styles.open : ""}`}>
      <div className={isOpen ? styles.overlay : ""} onClick={toggleSidebar}>

      </div>
        <IconContext.Provider value={{ color: "#4860A1", size: "30px" }} className={styles.closeIcon} >
          {/* <div >
            <IoCloseOutline onClick={toggleSidebar} />
          </div> */}
        </IconContext.Provider>
        <div className={styles.adminInfo}>
          <div className={styles.adminImage}>
            <img src={profilepicture} alt="" />
          </div>
          <h3>{adminName}</h3>
          <p>Admin</p>
        </div>
        <div className={styles.sidebarList}>
          {SidebarData.map((item) => (
            <div key={item.id} className={styles.sidebaritem}>
              {item.icon}
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
