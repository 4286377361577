import React from "react";
import styles from "./inputWithButton.module.css";
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";

const InputWithButton = ({ placeHolder, onChange, value, buttonText, onSubmit }) => {
  return (
    <div className={styles.inputButtonContainer}>
      <div className={styles.searchInput}>
        <IoIosSearch className={styles.searchIcon} />
        <input
          type="text"
          value={value}
          placeholder={placeHolder}
          onChange={onChange}
        />
      </div>
      <button onClick={onSubmit} className={styles.btnWrapper}>
        <FaPlus /> {buttonText}
      </button>
    </div>
  );
};

export default InputWithButton;
