import styles from "./receptionistBanner.module.css"
import { FaUserDoctor } from "react-icons/fa6";
import { FaAmbulance, FaUserNurse } from "react-icons/fa";
import { MdOutlineSick } from "react-icons/md";

const items = [
    {
      id: 1,
      icon: <MdOutlineSick size={30}/>,
      title: "New Patient",
      count: "50",
    },
    {
      id: 2,
      icon: <FaUserDoctor size={30}/>,
      title: "Available Doctors",
      count: "20",
    },
    {
      id: 3,
      icon: <FaAmbulance size={30}/>,
      title: "Ambulance",
      count: "50",
    },
    {
      id: 4,
      icon: <FaUserNurse size={30}/>,
      title: "Active Staff",
      count: "50",
    }
  ]

const ReceptionistBanner = () => {
  return (
    <div className={styles.receptionistBanner}>
      {
        items.map((item) => (
            <div className={styles.itemBox}>
                <div className={styles.iconBox}>{item.icon}</div>
                <div className={styles.textBox}>
                    <span>{item.title}</span> 
                    <h1>{item.count}</h1>
                </div>
            </div>
            )
        )
      }
    </div>
  )
}

export default ReceptionistBanner
