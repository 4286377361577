import styles from './appointmentList.module.css'
import { MdRefresh } from "react-icons/md";
import { AppointmentListData } from './appointmentListData';
import Card from '../card/card';

const AppointmentList = () => {
  return (
    <Card>
      <div className={styles.appointmentActions}>
        <h3>Appointment Request</h3>
        <div className={styles.refresh}>
          <MdRefresh />
          <p>View</p>
        </div>
      </div>
      <div>
        <table className={styles.apointmentTable}>
          <tr className={styles.apointmentTablerow}>
            <th>ID</th>
            <th>Patient Name</th>
            <th>Doctor</th>
            <th>Appointment Time</th>
            <th>Action</th>
          </tr>
          {AppointmentListData.map((item) => (
            <tr key={item.id}>
              <td>{item.fnumber}</td>
              <td>{item.patientName}</td>
              <td>{item.doctor}</td>
              <td>{item.appointmentTime}</td>
              <td className={styles.actionColumn}>{item.action}</td>
            </tr>
          ))}
        </table>
      </div>
    </Card>
  )
}

export default AppointmentList
