import React from "react";
import styles from './appointmentDetails.module.css'
import Card from "../card/card";

const AppointmentDetails = () => {
  return (
    <Card className={styles.card}>
      <h2 style={{ marginBottom: "1rem" }}>Appointment Details</h2>
      <form
        action=""
        method="post"
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        <div className={styles.formGroup}>
          <h4>Name</h4>
          <input type="text" placeholder="John Doe"/>
        </div>
        <div className={styles.formGroup}>
          <h4>Doctor</h4>
          <select>
            <option value="">Select Doctor</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <h4>Appointment Type</h4>
          <select>
            <option value="">Select Appointment Type</option>
          </select>
        </div>
        <div className={styles.formGroup}>
          <h4>Additional Note</h4>
          <textarea type="textarea" placeholder="Write Note"/>
        </div>
      </form>
    </Card>
  );
};

export default AppointmentDetails;
