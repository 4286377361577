export const QueueData = [
  {
    id: '01',
    fnumber: 'UI23/101',
    patientName: 'Adeleke Timothy',
    doctor: 'Adeleke Timothy',
    appointmentTime: '00 mm',
    action: 'Check in',
    serviceTime: '30 min'
  },
  {
    id: '02',
    fnumber: 'UI23/102',
    patientName: 'Clement Ojuade',
    doctor: 'Adeleke Timothy',
    appointmentTime: '3 min',
    action: 'Waiting',
    serviceTime: '25 min'
  },
  {
    id: '03',
    fnumber: 'UI23/103',
    patientName: 'Charles Wale',
    doctor: 'Adeleke Timothy',
    appointmentTime: '5 min',
    action: 'Waiting',
    serviceTime: '15 min'
  },
  {
    id: '04',
    fnumber: 'UI23/103',
    patientName: 'Muhammed Idris',
    doctor: 'Adeleke Timothy',
    appointmentTime: '10 min',
    action: 'Waiting',
    serviceTime: '20 min'
  },
  {
    id: '05',
    fnumber: 'UI23/103',
    patientName: 'Abubakar  Fahd',
    doctor: 'Abubakar  Fahd',
    appointmentTime: '20 min',
    action: 'Waiting',
    serviceTime: '30 min'
  },
  {
    id: '06',
    fnumber: 'UI23/103',
    patientName: 'Usman Musa',
    doctor: 'Usman Musa',
    appointmentTime: '25 min',
    action: 'Waiting',
    serviceTime: '1 hr'
  },
  {
    id: '07',
    fnumber: 'UI23/103',
    patientName: 'Saraki Yakubu',
    doctor: 'Saraki Yakubu',
    appointmentTime: '30 min',
    action: 'Waiting',
    serviceTime: '1 hr 30 min'
  }
]