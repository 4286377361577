import breastCancer from '../../assets/images/BreastCancerforMrsDunsin.png'
import holdingbaby from '../../assets/images/holdingbaby.png'
import emergencyRoom from '../../assets/images/emergencyroom.png'
import solarPanel from '../../assets/images/solarpanel.png'
import patientdoc from '../../assets/images/patientdoc.png'

const DonationsListData = [
  {
    id: 1,
    image: `${breastCancer}`,
    name: 'Breast Cancer for Mrs Dunsin',
    desc: 'Lorem ipsum dolor sit amet consectetur. Fames fusce pulvinar aliquam consectetur...',
    progress: 16,
    amount: '#50,000 raised of #500,000'
  },
  {
    id: 2,
    image: `${solarPanel}`,
    name: 'Solar panels to power the Clinic',
    desc: 'Lorem ipsum dolor sit amet consectetur. Fames fusce pulvinar aliquam consectetur...',
    progress: 57,
    amount: '#50,000 raised of #500,000'
  },
  {
    id: 3,
    image: `${holdingbaby}`,
    name: 'Breast Cancer for Mrs Dunsin',
    desc: 'Lorem ipsum dolor sit amet consectetur. Fames fusce pulvinar aliquam consectetur...',
    progress: 33,
    amount: '#50,000 raised of #500,000'
  },
  {
    id: 4,
    image: `${patientdoc}`,
    name: 'Breast Cancer for Mrs Dunsin',
    desc: 'Lorem ipsum dolor sit amet consectetur. Fames fusce pulvinar aliquam consectetur...',
    progress: 87,
    amount: '#50,000 raised of #500,000'
  },
  {
    id: 5,
    image: `${emergencyRoom}`,
    name: 'Breast Cancer for Mrs Dunsin',
    desc: 'Lorem ipsum dolor sit amet consectetur. Fames fusce pulvinar aliquam consectetur...',
    progress: 35,
    amount: '#50,000 raised of #500,000'
  },
  {
    id: 6,
    image: `${emergencyRoom}`,
    name: 'Breast Cancer for Mrs Dunsin',
    desc: 'Lorem ipsum dolor sit amet consectetur. Fames fusce pulvinar aliquam consectetur...',
    progress: 10,
    amount: '#50,000 raised of #500,000'
  },
]

export default DonationsListData