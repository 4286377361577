import styles from "./pharmacy.module.css";
import { IoPrintOutline } from "react-icons/io5";
import { IoIosSearch, IoMdOpen } from "react-icons/io";
import DonationsButton from "../../components/donationsButton/donationsButton";
import Wrapper from "../../components/wrapper/wrapper";
import MedicineTable from "../../components/medicineTable/medicineTable";

const Pharmacy = () => {
  return (
    <Wrapper navbarTitle={"List of Medicine"}>
      <div className={styles.group}>
        <div className={styles.filterGroup}>
          <input type="text" placeholder="Search Medicine Inventory" />
        </div>
        <div className={styles.secondfilterGroup}>
          <select name="" id="">
            <option value="">Search Group</option>
          </select>
          <DonationsButton label={"Add New Item"} />
        </div>
      </div>

      <MedicineTable height={"52px"} />
    </Wrapper>
  );
};

export default Pharmacy;
