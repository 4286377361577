import styles from './medicalRecords.module.css'
import Card from '../card/card'

const MedicalRecords = () => {
  return (
    <Card>
      <div className={styles.wrapper}>
        No Records
      </div>
    </Card>
  )
}

export default MedicalRecords
