// import styles from "./labTestRequest.module.css";
// import Navbar from "../../components/navbar/navbar";
import LabTestItem from "./lab-test-item/labTestItem";
import { LabTestRequestData } from "./labTestRequestData";
import Wrapper from "../../components/wrapper/wrapper";
// import Modal from '../../components/modal'

const LabTestRequest = () => {
  return (
    <Wrapper navbarTitle={"Lab Tests Request"}>
      {LabTestRequestData.map((item) => (
        <LabTestItem
          key={item.id}
          docName={item.docName}
          specialty={item.specialty}
          patientName={item.patientName}
          patientId={item.patientId}
          message={item.message}
        />
      ))}
    </Wrapper>
  );
};

export default LabTestRequest;
