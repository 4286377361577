import styles from "./medicalSessionBtn.module.css";

const MedicalSessionBtn = ({ icon, label, path, onClick }) => {
  return (
    <button onClick={onClick} className={styles.wrapperBtn}>
      {icon}
      {label}
    </button>
  );
};

export default MedicalSessionBtn;
