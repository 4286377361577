import styles from "./medicalSession.module.css";
import { IoIosSearch } from "react-icons/io";
import Wrapper from "../wrapper/wrapper";
import MedicalSessionPatientInfo from "../medicalSessionPatientInfo/medicalSessionPatientInfo";
import MedicalSessionBtnGroup from "../medicalSessionBtnGroup/medicalSessionBtnGroup";
import DonationsButton from "../donationsButton/donationsButton";
import Card from "../card/card";
import ShuffleColor from "../shuffleColor/shuffleColor";
import InputWithButton from "../inputWithButton/inputWithButton";
import { useState } from "react";

const MedicalSession = () => {
  const complaints = {
    1: "Cough",
    2: "Malaria",
    3: "Headache",
    4: "Sore throat",
    5: "Cough",
    6: "Malaria",
    7: "Headache",
    8: "Sore throat",
    9: "Cough",
    10: "Malaria",
    11: "Headache",
    12: "Sore throat",
  };

  const [inputText, setInputText] = useState("");

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = () => {
    // alert(`Input submitted: ${inputText}`);
    console.log(inputText);
    setInputText("");
  };

  return (
    <div>
      <InputWithButton
        placeHolder="Search Symptoms"
        onChange={handleChange}
        value={inputText}
        buttonText="Add"
        onSubmit={handleSubmit}
      />
      {/* <MedicalSessionPatientInfo />
      <MedicalSessionBtnGroup /> */}
      <div className={styles.contents}>
        {/* <div className={styles.filterGroup}>
          <div className={styles.searchInput}>
            <IoIosSearch className={styles.searchIcon} />
            <input type="text" placeholder="Search Symptoms" />
          </div>
          <DonationsButton label="Add" />
        </div> */}
        <Card className={styles.card} style={{ padding: "2rem" }}>
          <h3>Common Complaints</h3>
          {<ShuffleColor data={complaints} />}
        </Card>
        <Card className={styles.card}>
          <h2 style={{ marginBottom: "1rem" }}>Chief Complaints</h2>
          <form
            action=""
            method="post"
            style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
          >
            <div className={styles.formGroup}>
              <h4>Occurrence</h4>
              <input type="text" />
            </div>
            <div className={styles.formGroup}>
              <h4>Commencement of Symptoms</h4>
              <input type="text" />
            </div>
            <div className={styles.formGroup}>
              <h4>Allergies</h4>
              <input type="text" />
            </div>
            <div className={styles.formGroup}>
              <h4>Family History</h4>
              <input type="text" />
            </div>
            <div className={styles.formGroup}>
              <h4>Drug Used During Symptoms</h4>
              <input type="text" />
            </div>
            <div className={styles.formGroup}>
              <h4>Other Complain</h4>
              <input type="text" />
            </div>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default MedicalSession;
