export const PatientTableData = [
  {
    id: '01',
    fnumber: 'UI23/101',
    patientName: 'Adeleke Timothy',
    gender: 'F',
    contact: '+234 80000000',
    medication: 'Yes',
    lastVisit: '06-09-2023'
  },
  {
    id: '02',
    fnumber: 'UI23/102',
    patientName: 'Clement Ojuade',
    gender: 'F',
    contact: '+234 80000000',
    medication: 'No',
    lastVisit: '06-09-2023'
  },
  {
    id: '03',
    fnumber: 'UI23/103',
    patientName: 'Charles Wale',
    gender: 'F',
    contact: '+234 80000000',
    medication: 'No',
    lastVisit: '06-09-2023'
  },
  {
    id: '04',
    fnumber: 'UI23/103',
    patientName: 'Muhammed Idris',
    gender: 'F',
    contact: '+234 80000000',
    medication: 'No',
    lastVisit: '06-09-2023'
  },
  {
    id: '05',
    fnumber: 'UI23/103',
    patientName: 'Abubakar  Fahd',
    gender: 'F',
    contact: '+234 80000000',
    medication: 'No',
    lastVisit: '06-09-2023'
  },
  {
    id: '06',
    fnumber: 'UI23/103',
    patientName: 'Usman Musa',
    gender: 'F',
    contact: '+234 80000000',
    medication: 'No',
    lastVisit: '06-09-2023'
  },
  {
    id: '07',
    fnumber: 'UI23/103',
    patientName: 'Saraki Yakubu',
    gender: 'F',
    contact: '+234 80000000',
    medication: 'No',
    lastVisit: '06-09-2023'
  }
]