export const PrescriptionRequestData = [
    {
      id: 1,
      docName: 'Dr. Timothy Adeleke',
      specialty: 'Physician',
      patientId: ' UI23/101',
      message: 'Request 625 Duo Tablet, Augmentin 625 Duo Tablet, Ascoril LS Syrup for ',
      patientName: 'Miss Oyin'
    },
    {
      id: 2,
      docName: 'Dr. Timothy Adeleke',
      specialty: 'Physician',
      patientId: ' UI23/101',
      message: 'Request 625 Duo Tablet, Augmentin 625 Duo Tablet, Ascoril LS Syrup for ',
      patientName: 'Miss Oyin'
    },
    {
      id: 3,
      docName: 'Dr. Timothy Adeleke',
      specialty: 'Physician',
      patientId: ' UI23/101',
      message: 'Request 625 Duo Tablet, Augmentin 625 Duo Tablet, Ascoril LS Syrup for ',
      patientName: 'Miss Oyin'
    },
    {
      id: 4,
      docName: 'Dr. Timothy Adeleke',
      specialty: 'Physician',
      patientId: ' UI23/101',
      message: 'Request 625 Duo Tablet, Augmentin 625 Duo Tablet, Ascoril LS Syrup for ',
      patientName: 'Miss Oyin'
    }
  ]