import React from "react";
import styles from "./pageNotFound.module.css";
import PIC from "../../assets/images/404.jpg";
import { Link } from "react-router-dom";

function PageNotFound() {
  return (
    <div className={styles.main_container}>
      <div className={styles.pnf}>
        <h1 style={{ backgroundImage: `url(${PIC})` }}>404</h1>
        <h2>The Page you are requesting doesn't exist.</h2>
      </div>

      <div className={styles.button404}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <button className={styles.toHomeButton}>Go Back To Home</button>
        </Link>
      </div>
    </div>
  );
}

export default PageNotFound;