export const LabTestRequestData = [
  {
    id: 1,
    docName: 'Dr. Timothy Adeleke',
    specialty: 'Physician',
    patientId: ' UI23/101',
    message: 'A request for Urinalysis Test for ',
    patientName: 'Miss Oyin'
  },
  {
    id: 2,
    docName: 'Dr. Timothy Adeleke',
    specialty: 'Physician',
    patientId: ' UI23/101',
    message: 'A request for Blood Test for ',
    patientName: 'Miss Oyin'
  },
  {
    id: 3,
    docName: 'Dr. Timothy Adeleke',
    specialty: 'Physician',
    patientId: ' UI23/101',
    message: 'A request for Acid-Fast Bacillus (AFB) Tests  ',
    patientName: 'Miss Oyin'
  },
  {
    id: 4,
    docName: 'Dr. Timothy Adeleke',
    specialty: 'Physician',
    patientId: ' UI23/101',
    message: 'A request Aquaporin 4 Immunology Tests for',
    patientName: 'Miss Oyin'
  }
]