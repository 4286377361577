import React, { useState } from "react";
import Card from "../card/card";
import styles from "./Prescription.module.css";
import InputWithButton from "../inputWithButton/inputWithButton";

const Prescription = () => {
  const initialDrugsState = [
    { name: "Drug A", dosage: "5mg", count: 0 },
    { name: "Drug B", dosage: "10mg", count: 0 },
    { name: "Drug C", dosage: "20mg", count: 0 },
  ];

  const [drugs, setDrugs] = useState(initialDrugsState);

  const incrementCount = (index) => {
    const updatedDrugs = [...drugs];
    if (updatedDrugs[index].count < 20) {
      updatedDrugs[index].count++;
      setDrugs(updatedDrugs);
    }
  };

  const decrementCount = (index) => {
    const updatedDrugs = [...drugs];
    if (updatedDrugs[index].count > 0) {
      updatedDrugs[index].count--;
      setDrugs(updatedDrugs);
    }
  };

  const submitPrescription = () => {
    const prescriptionData = drugs.filter((drug) => drug.count > 0);
    console.log("Prescription Data:", prescriptionData);
  };

  const [inputText, setInputText] = useState("");

  const handleChange = (event) => {
    setInputText(event.target.value);
  };

  const handleSubmit = () => {
    // alert(`Input submitted: ${inputText}`);
    console.log(inputText);
    setInputText("");
  };

  return (
    <div className={styles.prescription}>
      <InputWithButton
        placeHolder="Search Drugs"
        onChange={handleChange}
        value={inputText}
        buttonText="Add"
        onSubmit={handleSubmit}
      />
      <Card>
        <table className={styles.prescriptionTable}>
          <tr className={styles.apointmentTablerow}>
            <th>Drugs</th>
            <th>Dosage</th>
          </tr>
          {drugs.map((drug, index) => (
            <tr key={index}>
              <td>{drug.name}</td>
              <td>
                <div className={styles.counter}>
                  <button onClick={() => decrementCount(index)}>-</button>
                  {drug.count.toString().padStart(2, "0")}
                  <button onClick={() => incrementCount(index)}>+</button>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </Card>
      <div className={styles.button}>
        <button onClick={submitPrescription} className={styles.request}>
          Request
        </button>
      </div>
    </div>
  );
};

export default Prescription;
