import ProgressBar from '../progress-bar/progressBar'
import styles from './donationsListItem.module.css'

const DonationsListItem = ({item}) => {
  return (
    <div className={styles.donationItem}>
      <div className={styles.donationImage}>
        <img src={item.image} alt="" />
      </div>
      <div className={styles.donationsInfo}>
        <h3>{item.name}</h3>
        <p>{item.desc}</p>
        <ProgressBar progress={item.progress} />
        <p>{item.amount}</p>
      </div>
    </div>
  )
}

export default DonationsListItem
