import "./index.css";
import Sidebar from "./components/sidebar/sidebar";

export default function Layout({ children }) {
  return (
    <div className="layout">
      <div className="sidebar">
        <Sidebar adminName={"Vivian Rose"} />
      </div>
      <div className="page">
      {children}
      </div>
    </div>
  );
}
