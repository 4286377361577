import './index.css';
import DonationsList from './pages/donationsList/donationsList';
import Dashboard from './pages/dashboard/dashboard';
import LabTestRequest from './pages/lab-test-request/labTestRequest';
import QueueList from './pages/queueList/queueList';
import PatientList from './pages/patientList/patientList';
import MedicalSummary from './pages/medicalSummary/medicalSummary';
import DoctorDashboard from './pages/doctorDashboard/doctorDashboard';
import MedicalSession from './components/medicalSession/medicalSession';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Layout from './Layout';
import VitalSigns from './pages/vitalSigns/vitalSigns';
import PageNotFound from './pages/pageNotFound/pageNotFound';
import Appointment from './pages/appointment/appointment';
import AppointmentConfirmation from './pages/appointmentConfirmation/appointmentConfirmation';
import PrescriptionRequest from './pages/prescriptionRequest/prescriptionRequest';
import Pharmacy from './pages/pharmacy/pharmacy';
import Prescriptions from './pages/prescriptions/prescriptions';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Layout><Dashboard /></Layout>} />
        <Route path='/queue-list' element={<Layout><QueueList /></Layout>} />
        <Route path='/lab-test-request' element={<Layout><LabTestRequest /></Layout>} />
        <Route path='/patient-list' element={<Layout><PatientList /></Layout>} />
        <Route path='/medical-summary' element={<Layout><MedicalSummary /></Layout>} />
        <Route path='/donation-list' element={<Layout><DonationsList /></Layout>} />
        <Route path='/doctors-dashboard' element={<Layout><DoctorDashboard /></Layout>} />
        <Route path='/appointment' element={<Layout><Appointment /></Layout>} />
        <Route path='/appointment-confirmation' element={<Layout><AppointmentConfirmation /></Layout>} />
        <Route path='/prescription-request' element={<Layout><PrescriptionRequest /></Layout>} />
        <Route path='/pharmacy' element={<Layout><Pharmacy /></Layout>} />
        <Route path='/prescriptions' element={<Layout><Prescriptions /></Layout>} />
        {/* <Route path='/medical-session' element={<Layout><MedicalSession /></Layout>} /> */}
        {/* <Route path='/vital-signs' element={<Layout><VitalSigns /></Layout>} /> */}
        <Route path='/*' element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;