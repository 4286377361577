import React from 'react'
import DatePicker from '../../components/datePicker/datePicker'
import ResponsiveDatePickers from '../../components/datePicker/datePicker'

const Appointment = () => {
  return (
    <div>
      <ResponsiveDatePickers />
    </div>
  )
}

export default Appointment
