import styles from './donationsButton.module.css'
import { FaPlus } from "react-icons/fa6";


const DonationsButton = ({label}) => {
  return (
    <button className={styles.btnWrapper}>
      <FaPlus /> {label}
    </button>
  )
}

export default DonationsButton
