import styles from "./queueList.module.css";
import Queue from "../../components/queue/queue";
import { MdRefresh } from "react-icons/md";
import Wrapper from "../../components/wrapper/wrapper";

const QueueList = () => {
  return (
    <Wrapper navbarTitle={"Queue List"}>
      <div className={styles.filterGroup}>
        <input type="text" placeholder="Search by Name" />
        <select name="" id="">
          <option value="">All Doctors</option>
        </select>
        <select name="" id="">
          <option value="">All Status</option>
        </select>
        {/* <MdRefresh className={styles.refreshIcon} /> */}
      </div>

      <Queue height={"80px"} actions={"none"} />
    </Wrapper>
  );
};

export default QueueList;
