import AppointmentList from "../../components/appointmentList/appointmentList";
import Queue from "../../components/queue/queue";
import ReceptionistBanner from "../../components/receptionistBanner/receptionistBanner";
import Wrapper from "../../components/wrapper/wrapper";
import styles from "./dashboard.module.css";

const Dashboard = () => {
  return (
    <Wrapper navbarTitle={"Dashboard"}>
      <ReceptionistBanner />
      <AppointmentList />
      <div className={styles.queue}>
        <div className={styles.part1}>
          <Queue sliceValue={4} display={"none"} />
        </div>
        <div className={styles.part2}>
          <div className={styles.subCards}>
            <p className={styles.inSession}>In-session</p> <p>02</p>
          </div>
          <div className={styles.subCards}>
            <p className={styles.waiting}>Waiting</p> <p>15</p>
          </div>
          <div className={styles.subCards}>
            <p className={styles.checkins}>Total Check-Ins</p> <p>17</p>
          </div>
          <div className={styles.subCards}>
            <p className={styles.checkins}>Total Appointment</p> <p>20</p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Dashboard;
