import React from 'react'
import styles from './doctorCard.module.css'

const DoctorCard = ({bgColor, color, icon, detail, count, date}) => {
  return (
    <div style={{color: color, backgroundColor: bgColor}} className={styles.doctorCard}>
      <div className={styles.left}>
        {icon}
      </div>
      <div className={styles.right}>
        <p>{detail}</p>
        <h1>{count}</h1>
        <p>{date}</p>
      </div>
    </div>
  )
}

export default DoctorCard
