import styles from './card.module.css'

const Card = ({ children, bgColor }) => {
  return (
    <div style={{backgroundColor: bgColor}} className={styles.wrapper}>
      {children}
    </div>
  )
}

export default Card
