import styles from './queue.module.css'
import { MdRefresh } from 'react-icons/md'
import Card from '../card/card'
import { QueueData } from './queueData'
import { useState } from 'react'

const Queue = ({height, sliceValue, display, actions}) => {

  return (
    <Card>
      <div style={{display: actions}} className={styles.appointmentActions}>
        <h3>Queue</h3>
        <div className={styles.refresh}>
          <MdRefresh />
          <p>View</p>
        </div>
      </div>
      <div>
        <table className={styles.apointmentTable}>
          <tr className={styles.apointmentTablerow}>
            <th>S/N</th>
            <th>Patient Name</th>
            <th>Doctor</th>
            <th>Estimated Wait Time</th>
            <th style={{display: display}}>Estimated Service Time</th>
            <th>Status</th>
          </tr>
          {QueueData.slice(0, sliceValue).map((item) => (
            <tr style={{height: height}} key={item.id}>
              <td>{item.id}</td>
              <td>{item.patientName}</td>
              <td>{item.doctor}</td>
              <td>{item.appointmentTime}</td>
              <td style={{display: display}}>{item.serviceTime}</td>
              <td>
              <p className={item.action === 'Waiting'? styles.actionColumn2 : styles.actionColumn }>{item.action}</p>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </Card>
  )
}

export default Queue
