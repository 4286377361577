import React from 'react';
import styles from './shuffleColor.module.css'

const colors = ['#6ADB77', '#F9B3B6', '#B6BFD9', '#FBE7AC']; 

function ShuffleColor({ data }) {
    let colorIndex = 0; // Index to keep track of the current color

    return (
      <div className={styles.shuffleObjects}>
        {Object.entries(data).map(([key, value]) => (
          <p key={key} style={{ backgroundColor: colors[colorIndex++ % colors.length] }}>
            {value}
          </p>
        ))}
      </div>
  );
}

export default ShuffleColor;
