import Card from '../card/card'
import Pagination from '../pagination/pagination'
import { MedicineTableData } from './medicineTableData'
import styles from './medicineTable.module.css'

const MedicineTable = ({sliceValue, actions, height, display}) => {
  return (
    <Card>
    <div>
      <table className={styles.apointmentTable}>
        <tr className={styles.apointmentTablerow}>
          <th>Medicine Name</th>
          <th>Medicine ID</th>
          <th>Group Name</th>
          <th>Stock in Qty</th>
          <th>Action</th>
        </tr>
        {MedicineTableData.slice(0, sliceValue).map((item) => (
          <tr style={{height: height}} key={item.id}>
            <td>{item.name}</td>
            <td>{item.medicineID}</td>
            <td>{item.groupName}</td>
            <td>{item.quantity}</td>
            <td className={styles.view}>View</td>
          </tr>
        ))}
      </table>
    </div>
    <Pagination />
  </Card>
  )
}

export default MedicineTable
