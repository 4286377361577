import styles from "./medicalSummary.module.css";
import Wrapper from "../../components/wrapper/wrapper";
import MedicalSessionPatientInfo from "../../components/medicalSessionPatientInfo/medicalSessionPatientInfo";
import MedicalSessionBtnGroup from "../../components/medicalSessionBtnGroup/medicalSessionBtnGroup";
import Summary from "../../components/summary/summary";
import { useState } from "react";
import MedicalRecords from "../../components/medicalRecords/medicalRecords";
import Prescription from "../../components/Prescription/Prescription";
import LabTest from "../../components/labTest/labTest";
import MedicalRecord from "../../components/medicalRecord/medicalRecord";
import MedicalSession from "../../components/medicalSession/medicalSession";
import Diagnosis from "../../components/diagnosis/diagnosis";

const MedicalSummary = () => {
  const [innerPage, setInnerPage] = useState(1)

  const PagesWithin = [
    {
      id: 1,
      element: <Summary />
    },
    {
      id: 2,
      element: <MedicalRecords />
    },
    {
      id: 3,
      element: <MedicalSession />,
    },
    // Vital Signs here
    // {
    //   id: 4,
    //   element: <Diagnosis />,
    // },
    {
      id: 5,
      element: <Diagnosis />,
    },
    {
      id: 6,
      element: <Prescription />,
    },
    {
      id: 7,
      element: <LabTest />,
    }
  ]
  

  return (
    <Wrapper navbarTitle={"Medical Session"}>
      <MedicalSessionPatientInfo />
      <MedicalSessionBtnGroup pageWithin={setInnerPage} />
      <div className={styles.container}>
        {PagesWithin.map((item)=>(
          <div key={item.id}>
            {innerPage === item.id && item.element}
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default MedicalSummary;
