import React from 'react'
import Rafiki from '../../assets/images/rafiki.png'
import styles from './doctorBanner.module.css'

const DoctorBanner = ({name}) => {
  return (
    <div className={styles.doctorBanner}>
      <div className={styles.left}>
        <h6>Welcome</h6>
        <h1>Dr. {name}</h1>
      </div>
      <div className={styles.right}>
        <img src={Rafiki} alt="Doctor icon" />
      </div>
    </div>
  )
}

export default DoctorBanner
