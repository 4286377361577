export const MedicineTableData = [
    {
      id: '01',
      name: 'Azithral 500 Tablet',
      medicineID: 'D06ID232435454',
      groupName: 'Generic Medicine',
      quantity: '350',
    },
    {
      id: '02',
      name: 'Azithral 500 Tablet',
      medicineID: 'D06ID232435451',
      groupName: 'Diabetes',
      quantity: '85',
    },
    {
      id: '03',
      name: 'Ascoril LS Syrup',
      medicineID: 'D06ID232435450',
      groupName: 'Generic Medicine',
      quantity: '75',
    },
    {
      id: '04',
      name: 'Allegra 120mg Tablet',
      medicineID: 'D06ID232435455',
      groupName: 'Diabetes',
      quantity: '44',
    },
    {
      id: '05',
      name: 'Ascoril LS Syrup',
      medicineID: 'D06ID232435450',
      groupName: 'Generic Medicine',
      quantity: '75',
    },
    {
      id: '06',
      name: 'Ascoril LS Syrup',
      medicineID: 'D06ID232435450',
      groupName: 'Generic Medicine',
      quantity: '75',
    },
    {
      id: '07',
      name: 'Ascoril LS Syrup',
      medicineID: 'D06ID232435450',
      groupName: 'Generic Medicine',
      quantity: '75',
    },
  ]