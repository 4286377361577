import Card from '../card/card'
import Pagination from '../pagination/pagination'
import styles from './patientTable.module.css'
import { PatientTableData } from './patientTableData'

const PatientTable = ({sliceValue, actions, height, display}) => {
  return (
    <Card>
    <div>
      <table className={styles.apointmentTable}>
        <tr className={styles.apointmentTablerow}>
          <th>ID</th>
          <th>Patient Name</th>
          <th>Gender</th>
          <th>Contact</th>
          <th>Last Visit</th>
          <th>Medication</th>
        </tr>
        {PatientTableData.slice(0, sliceValue).map((item) => (
          <tr style={{height: height}} key={item.id}>
            <td>{item.fnumber}</td>
            <td>{item.patientName}</td>
            <td>{item.gender}</td>
            <td>{item.contact}</td>
            <td>{item.lastVisit}</td>
            <td>{item.medication}</td>
          </tr>
        ))}
      </table>
    </div>
    <Pagination />
  </Card>
  )
}

export default PatientTable
