import React from "react";
import MedicalSessionPatientInfo from "../../components/medicalSessionPatientInfo/medicalSessionPatientInfo";
import MedicalSessionBtnGroup from "../../components/medicalSessionBtnGroup/medicalSessionBtnGroup";
import styles from "./diagnosis.module.css";
import Wrapper from "../../components/wrapper/wrapper";
import Card from "../card/card";

const Diagnosis = () => {
  return (
    <Card>
      {/* <MedicalSessionPatientInfo />
      <MedicalSessionBtnGroup /> */}
      <div className={styles.contents}>
        <textarea placeholder="Type here" className={styles.textarea} />
      </div>
    </Card>
  );
};

export default Diagnosis;
