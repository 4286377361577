export const AppointmentListData = [
  {
    id: 1,
    fnumber: 'UI23/101',
    patientName: 'Adeleke Timothy',
    doctor: 'Adeleke Timothy',
    appointmentTime: '12;30 PM',
    action: 'Check in'
  },
  {
    id: 2,
    fnumber: 'UI23/102',
    patientName: 'Clement Ojuade',
    doctor: 'Adeleke Timothy',
    appointmentTime: '12;30 PM',
    action: 'Check in'
  },
  {
    id: 3,
    fnumber: 'UI23/103',
    patientName: 'Charles Wale',
    doctor: 'Adeleke Timothy',
    appointmentTime: '12;30 PM',
    action: 'Check in'
  },
  {
    id: 4,
    fnumber: 'UI23/104',
    patientName: 'Muhammed Idris',
    doctor: 'Adeleke Timothy',
    appointmentTime: '12;30 PM',
    action: 'Check in'
  },
  {
    id: 5,
    fnumber: 'UI23/105',
    patientName: 'Abubakar  Fahd',
    doctor: 'Adeleke Timothy',
    appointmentTime: '12;30 PM',
    action: 'Check in'
  }
]