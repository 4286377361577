import React from "react";
import styles from "./labTest.module.css";
import MainButton from '../../components/mainButton/mainButton'

const LabTest = () => {

const handleClick = () => {
  // console.log("Hey")
}

  const Tests = [
    {
      id: 1,
      title: "Microbiology",
      test: ["Urinalysis", "In-session", "Waiting"],
    },
    {
      id: 2,
      title: "Blood Smear",
      test: ["Malaria", "Ready", "Positive"],
    },
    {
      id: 3,
      title: "Blood Smear",
      test: ["Thyroid Test (T3)", "Ready", "4.0mU/L"],
    },
    {
      id: 4,
      title: "Blood Smear",
      test: ["Malaria", "Waiting", "Waiting"],
    },
  ];

  return (
    <div className={styles.labTest}>
      {Tests.map((item) => (
        <div className={styles.testBox}>
          <div key={item.id} className={styles.test_container}>
            <h3>{item.title}</h3>
            <div className={styles.blue_container}>
              <span>Test Name</span>
              <span>Status</span>
              <span>Results</span>
            </div>
            <div className={styles.white_container}>
              {item.test.map((test, index) => (
                <div
                  key={index}
                  className={`${styles.labtest_input} ${
                    test === "In-session"
                      ? styles.greenBackground
                      : test === "Waiting"
                      ? styles.yellowBackground
                      : ""
                  }`}
                >
                  <span>{test}</span>
                </div>
              ))}
            </div>
          </div>
          <MainButton title="Request" handleClick={handleClick} backgroundColor={"#23408E"}/>
        </div>
      ))}
    </div>
  );
};

export default LabTest;
