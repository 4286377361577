import { MdOutlineDashboard } from "react-icons/md";
import { CiViewList } from "react-icons/ci";
import { CiMedicalCase } from "react-icons/ci";
import { MdMoney } from "react-icons/md";
import { MdOutlineReviews } from "react-icons/md";
import { CiSettings } from "react-icons/ci";

export const SidebarData = [
  {
    id: 1,
    icon: <MdOutlineDashboard />,
    label: 'Dashboard'
  },
  {
    id: 2,
    icon: <CiViewList />,
    label: 'Staff'
  },
  {
    id: 3,
    icon: <CiMedicalCase />,
    label: 'Sessions'
  },
  {
    id: 4,
    icon: <MdMoney />,
    label: 'Donations'
  },
  {
    id: 5,
    icon: <MdOutlineReviews />,
    label: 'Reviews'
  },
  {
    id: 6,
    icon: <CiSettings />,
    label: 'Settings'
  },
]