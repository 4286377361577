import styles from "./medicalSessionBtnGroup.module.css";
import MedicalSessionBtn from "../medicalSessionBtn/medicalSessionBtn";
import { BsClipboard2Heart } from "react-icons/bs";
import { MdOutlineMedicalInformation } from "react-icons/md";
import { PiStethoscopeBold } from "react-icons/pi";
import { LuActivitySquare } from "react-icons/lu";
import { BsClipboard2Pulse } from "react-icons/bs";
import { LiaPillsSolid } from "react-icons/lia";
import { RiMicroscopeLine } from "react-icons/ri";

const MedicalSessionBtnGroup = ({pageWithin}) => {
    const ButtonGroup = [
        {
            id: 1,
            icon: <BsClipboard2Heart />,
            label: "Summary",
            path: "/medical-summary/summary",
        },
        {
            id: 2,
            icon: <MdOutlineMedicalInformation />,
            label: "Medical Records",
            path: "/medical-summary/records",
        },
        {
            id: 3,
            icon: <PiStethoscopeBold />,
            label: "Medical Session",
        },
        {
            id: 4,
            icon: <LuActivitySquare />,
            label: "Vitals",
        },
        {
            id: 5,
            icon: <BsClipboard2Pulse />,
            label: "Diagnosis",
        },
        {
            id: 6,
            icon: <LiaPillsSolid />,
            label: "Prescription",
        },
        {
            id: 7,
            icon: <RiMicroscopeLine />,
            label: "Lab Tests",
        },
    ];

    const medical = (id) => {
      pageWithin(id)
    }

    return (
        <div className={styles.wrapper}>
            {ButtonGroup.map((item) => (
                <MedicalSessionBtn
                    key={item.id}
                    path={item.path}
                    icon={item.icon}
                    label={item.label}
                    onClick={() => medical(item.id)}
                />
            ))}
        </div>
    );
};

export default MedicalSessionBtnGroup;
