import Navbar from '../navbar/navbar'
import styles from './wrapper.module.css'

const Wrapper = ({navbarTitle, children}) => {
  return (
    <main className={styles.wrapper}>
      <Navbar title={navbarTitle} />
      <div className={styles.content}>
        {children}
      </div>
    </main>
  )
}

export default Wrapper
