import { MdOutlineSick } from "react-icons/md";
import { CiViewList } from "react-icons/ci";

export const DoctorDashboardData = [
  {
    id: 1,
    bgColor: "#4860A1",
    color: "#fff",
    icon: <MdOutlineSick size={30}/>,
    detail: "Patients this week", 
    count: 1500, 
    date: "Till Today"
  },
  {
    id: 2,
    bgColor: "#FBD1D3",
    color: "#000",
    icon: <MdOutlineSick size={30}/>,
    detail: "Today’s Patient", 
    count: 38, 
    date: "6th Oct., 2023"
  },
  {
    id: 3,
    bgColor: "#F5F5F5",
    color: "#000",
    icon: <CiViewList size={30}/>,
    detail: "Today’s Appointment", 
    count: 50, 
    date: "6th Oct., 2023"
  },
];


export const AppointmentQueueData = [
    {
      id: '01',
      fnumber: 'UI23/101',
      patientName: 'Adeleke Timothy',
      doctor: 'Adeleke Timothy',
      appointmentTime: 'Check in',
      action: 'Check in',
      serviceTime: '30 min'
    },
    {
      id: '02',
      fnumber: 'UI23/102',
      patientName: 'Clement Ojuade',
      doctor: 'Adeleke Timothy',
      appointmentTime: '3 min',
      action: 'Waiting',
      serviceTime: '25 min'
    },
    {
      id: '03',
      fnumber: 'UI23/103',
      patientName: 'Charles Wale',
      doctor: 'Adeleke Timothy',
      appointmentTime: '5 min',
      action: 'Waiting',
      serviceTime: '15 min'
    },
    {
      id: '04',
      fnumber: 'UI23/103',
      patientName: 'Muhammed Idris',
      doctor: 'Adeleke Timothy',
      appointmentTime: '10 min',
      action: 'Waiting',
      serviceTime: '20 min'
    },
    {
      id: '05',
      fnumber: 'UI23/103',
      patientName: 'Abubakar  Fahd',
      doctor: 'Abubakar  Fahd',
      appointmentTime: '20 min',
      action: 'Waiting',
      serviceTime: '30 min'
    },
    {
      id: '06',
      fnumber: 'UI23/103',
      patientName: 'Usman Musa',
      doctor: 'Usman Musa',
      appointmentTime: '25 min',
      action: 'Waiting',
      serviceTime: '1 hr'
    },
    {
      id: '07',
      fnumber: 'UI23/103',
      patientName: 'Saraki Yakubu',
      doctor: 'Saraki Yakubu',
      appointmentTime: '30 min',
      action: 'Waiting',
      serviceTime: '1 hr 30 min'
    }
  ]

export const Appointments = [
  {
    id: 1,
    name: "Appointment Request",
  },
  {
    id: 2,
    name: "Pharmaceutical Request",
  },
  {
    id: 1,
    name: "Chat",
    count: 2
  },
  {
    id: 1,
    name: "Unread Chat",
    count: 2
  }
]

export const ratingLevel = [
  {
    id: 1,
    progress: (16/20)*100,
    amount: 'Excellent'
  },
  {
    id: 2,
    progress: (8/20)*100,
    amount: 'Great'
  },
  {
    id: 3,
    progress: (4/20)*100,
    amount: 'Good'
  },
  {
    id: 4,
    progress: (2/20)*100,
    amount: 'Average'
  },
]