import styles from "./doctorDashboard.module.css";
import Navbar from "../../components/navbar/navbar";
import DoctorBanner from "../../components/doctorBanner/doctorBanner";
import {
  AppointmentQueueData,
  Appointments,
  DoctorDashboardData,
  ratingLevel,
} from "./doctorDashboardData";
import DoctorCard from "../../components/doctorCard/doctorCard";
import { MdRefresh } from "react-icons/md";
import Card from "../../components/card/card";
import { PieChart } from "@mui/x-charts/PieChart";
import { useState } from "react";
import ProgressBar from "../../components/progress-bar/progressBar";

const DoctorDashboard = () => {
  const [sliceValue, setSliceValue] = useState(10);

  return (
    <main className={styles.wrapper}>
      <Navbar title={"Dashboard"} />
      <div className={styles.content}>
        <DoctorBanner name="Daniel Samuel" />
        <div className={styles.cardContents}>
          {DoctorDashboardData.map((item) => (
            <DoctorCard
              bgColor={item.bgColor}
              color={item.color}
              key={item.id}
              icon={item.icon}
              detail={item.detail}
              count={item.count}
              date={item.date}
            />
          ))}
        </div>
        <div className={styles.doctorsBottom}>
          <div className={styles.doctorsAppointment}>
            <Card>
              <div className={styles.appointmentActions}>
                <h3>Today's Appointment</h3>
              </div>
              <div>
                <table className={styles.apointmentTable}>
                  <tr className={styles.apointmentTablerow}>
                    <th>Patient</th>
                    <th>Name/Dignosis</th>
                    <th>Time</th>
                  </tr>
                  {AppointmentQueueData.slice(0, sliceValue).map((item) => (
                    <tr style={{ height: item.height }} key={item.id}>
                      <td>{item.id}</td>
                      <td>{item.patientName}</td>
                      <td>
                        <p
                          className={
                            item.appointmentTime === "Check in"
                              ? styles.actionColumn2
                              : styles.actionColumn
                          }
                        >
                          {item.appointmentTime}
                        </p>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </Card>
          </div>
          <div className={styles.notifications}>
            <Card>
              <div className={styles.appointmentActions}>
                <h3>Notifications</h3>
                <div className={styles.refresh}>
                  <p>View all</p>
                </div>
              </div>
              <div className={styles.contents}>
                {Appointments.map((appointment) => (
                  <Card className="card" key={appointment.id} bgColor="#F5F5F5">
                    <div className={styles.top}>
                      <h3>{appointment.name}</h3>
                      {appointment.count && <p>({appointment.count})</p>}
                    </div>
                    <span>View</span>
                  </Card>
                ))}
              </div>
            </Card>
          </div>
          <div className={styles.commonIllness}>
            <Card>
              <div className={styles.appointmentActions}>
                <h3>Common Illness</h3>
              </div>
              <PieChart
                series={[
                  {
                    data: [
                      { id: 0, value: 10, color: "#F9B3B6", label: "Others" },
                      { id: 1, value: 15, color: "#DDDDDD", label: "Cold" },
                      { id: 2, value: 20, color: "#4860A1", label: "Malaria" },
                    ],
                    highlightScope: { faded: "global", highlighted: "item" },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                width={300}
                height={230}
              />
            </Card>
            <Card>
              {ratingLevel.map((item) => (
                <div className={styles.rating}>
                  <p>{item.amount}</p>
                  <ProgressBar
                    className="progressBar"
                    progress={item.progress}
                  />
                </div>
              ))}
            </Card>
          </div>
        </div>
      </div>
    </main>
  );
};

export default DoctorDashboard;
