import styles from './mainButton.module.css'

const MainButton = ({title, link, handleClick, backgroundColor}) => {
  return (
    <button onClick={handleClick} className={styles.button} style={{backgroundColor: backgroundColor}}>
        <a href={link}>
          <span>{title}</span>
        </a>
    </button>
  )
}

export default MainButton
