import styles from './donationsList.module.css'
import DonationsListItem from '../../components/donationsListItem/donationsListItem'
import DonationsListData from './donationsListData'
import DonationsButton from '../../components/donationsButton/donationsButton'
// import Navbar from '../../components/navbar/navbar'
import Wrapper from '../../components/wrapper/wrapper'

const DonationsList = () => {
  return (
    <Wrapper navbarTitle={'Donations'}>
      <div className={styles.btnContainer}>
        <DonationsButton label={'Create Donation'} />
      </div>
      <div className={styles.donationsList}>
        {DonationsListData.map((item) => (
          <DonationsListItem item={item} />
        ))}
      </div>
    </Wrapper>
  )
}

export default DonationsList