import styles from './summary.module.css'

const Summary = () => {
  const InfectionsBox = [
    {
      id: 1,
      title: "Chief Symptoms",
      infectionTitle: ["Infection"],
      infection: ["Cough", "Cold", "Fever"],
    },
    {
      id: 2,
      title: "Diagnosis",
      infectionTitle: ["Infection"],
      infection: ["Typhoid"],
    },
    {
      id: 3,
      title: "Lab Test Result",
      infectionTitle: ["Test Type: Thyphoid"],
      infection: ["Positive:", "Negative:", "Neutral:", "Critical:"],
    },
    {
      id: 4,
      title: "Prescription",
      infectionTitle: ["Paracetamol500mg", "Loratidine10mg", "Ampiclox25mg", "Amartem 5mg"],
      ijnfection: ["2/7", "2/7", "4/7", "5/7"],
    },
  ];  

  return (
    <div className={styles.summary}>
      {InfectionsBox.map((item) => (
        <div key={item.id} className={styles.symptoms_container}>
          <h3>{item.title}</h3>
          <div className={styles.blue_container}>
            {item.infectionTitle.map((title) => (
              <span>{title}</span>
            ))}
          </div>
          <div className={styles.white_container}>
            {item?.infection?.map((disease) => (
              <div className={styles.labtest_input}>
                <span>{disease}</span>
                {item.title === "Lab Test Result" && <input type="checkbox" />}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Summary
