import React from "react";
import DatePicker from "../../components/datePicker/datePicker";
import ResponsiveDatePickers from "../../components/datePicker/datePicker";
import Navbar from "../../components/navbar/navbar";
import styles from "./appointmentConfirmation.module.css";
import AppointmentDetails from "../../components/appointmentDetails/appointmentDetails";
import Card from "../../components/card/card";
import ResponsiveTimePickers from "../../components/timePicker/timePicker";
import MainButton from "../../components/mainButton/mainButton";

const AppointmentConfirmation = () => {
  const handleClick = () => {};

  return (
    <div className={styles.wrapper}>
      <Navbar title={"Confirm Appointment"} />
      <div className={styles.content}>
        {/* <div className={styles.left}>
          <ResponsiveDatePickers />
          <Card>
            <div className={styles.formGroup}>
              <h4>Time</h4>
              <ResponsiveTimePickers />
            </div>
          </Card>
        </div> */}
        <div className={styles.left}>
          <AppointmentDetails />
        </div>
        <div className={styles.right}>
        <Card>
            <div className={styles.formGroup}>
              <h4>Date</h4>
              <ResponsiveTimePickers />
            </div>
          </Card>
          <Card>
            <div className={styles.formGroup}>
              <h4>Time</h4>
              <ResponsiveTimePickers />
            </div>
          </Card>
          <Card className={styles.card}>
            <h2 style={{ marginBottom: "1rem" }}>Appointment Details</h2>
            <form
              action=""
              method="post"
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div className={styles.formGroup}>
                <h4>Name</h4>
                <input type="text" placeholder="John Doe" />
              </div>
              <div className={styles.formGroup}>
                <h4>Age</h4>
                <input type="text" placeholder="20" />
              </div>
              <div className={styles.formGroup}>
                <h4>Phone Number</h4>
                <input type="text" placeholder="000 0000 000" />
              </div>
            </form>
          </Card>
        </div>
      </div>
      <MainButton
        title="Confirm Appointment"
        handleClick={handleClick}
        backgroundColor={"#23408E"}
      />
    </div>
  );
};

export default AppointmentConfirmation;
